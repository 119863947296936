import { all, put, takeLatest } from "redux-saga/effects";
import { apiFetch } from "../../utility/fetch-utils";
import { loadingInterceptor } from "../../utility/loading-interceptor";

import { ActionTypes } from "../constant/constant";

import { toast } from "react-toastify";

import {
  setAgentProductMappingDTOInCookie,
  setLeadDTOInCookie,
  setUserDetailInCookie,
} from "../../utility/session-utils";

export function* performLogout({ payload }) {
  yield put({
    type: ActionTypes.UPDATE_IS_AUTHENTICATED,
    payload: {
      isUserAuthenticated: false,
    },
  });

  // const response = yield apiFetch(`account/signout`, {
  //   method: "POST",
  // }).then((res) => res);

  // if (response.status === 200) {
  //   yield put({
  //     type: ActionTypes.UPDATE_IS_AUTHENTICATED,
  //     payload: {
  //       isUserAuthenticated: false,
  //     },
  //   });
  //   removeCookie();
  // } else {
  //   toast.configure();
  //   toast.error("User is not logout. Please try again.");
  // }
}
export function* apiFindAllInsurerTypes({ payload }) {
  const response = yield apiFetch(`insurer-types/all`, {
    method: "GET",
  }).then((res) => res);

  if (response.status === 200) {
    const responseJSON = yield response.json();

    yield put({
      type: ActionTypes.FIND_ALL_INSURER_TYPES_RESPONSE,
      payload: {
        data: responseJSON.responseObj,
      },
    });
  } else {
    // toast.configure();
    // toast.error("Something went wrong.");

    yield put({
      type: ActionTypes.FIND_ALL_INSURER_TYPES_RESPONSE,
      payload: {
        data: [],
      },
    });
  }
}
export function* apiCheckUserProfileCompletion({ payload }) {
  const response = yield apiFetch(`profile/check-profile-completion`, {
    method: "GET",
  }).then((res) => res);

  if (response.status === 200) {
    const responseJSON = yield response.json();

    yield put({
      type: ActionTypes.CHECK_USER_PROFILE_COMPLETION_RESPONSE,
      payload: {
        data: responseJSON.responseObj,
      },
    });
  } else {
    yield put({
      type: ActionTypes.CHECK_USER_PROFILE_COMPLETION_RESPONSE,
      payload: {
        data: {},
      },
    });
    // toast.configure();
    // toast.error("Something went wrong.");
  }
}

export function* getAgentProductMappingData({ payload }) {
  const leadId = payload.leadId;
  const response = yield apiFetch(`/api/agent-product-mapping/${leadId}`, {
    method: "GET",
  }).then((res) => res);

  if (response.status === 200) {
    var responseJSON = null;
    try {
      responseJSON = yield response.json();
    } catch (error) {
      console.error(error);
    }
    if (responseJSON) {
      setAgentProductMappingDTOInCookie(
        responseJSON.responseObj.agentProductMappingDTO
      );
      setLeadDTOInCookie(responseJSON.responseObj.leadDTO.detailedDTO);
      setUserDetailInCookie(responseJSON.responseObj.userDTO);

      yield put({
        type: ActionTypes.UPDATE_IS_AUTHENTICATED,
        payload: {
          isUserAuthenticated: true,
        },
      });
    }
  }

  yield put({
    type: ActionTypes.FIND_ALL_INSURER_TYPES,
    payload: {},
  });

  yield put({
    type: ActionTypes.CHECK_USER_PROFILE_COMPLETION,
    payload: {},
  });
}

export default function* root() {
  yield all([
    takeLatest(
      ActionTypes.CHECK_USER_PROFILE_COMPLETION,
      loadingInterceptor,
      apiCheckUserProfileCompletion
    ),
    takeLatest(
      ActionTypes.FIND_ALL_INSURER_TYPES,
      loadingInterceptor,
      apiFindAllInsurerTypes
    ),

    takeLatest(
      ActionTypes.GET_AGENT_PRODUCT_MAPPING,
      loadingInterceptor,
      getAgentProductMappingData
    ),
    takeLatest(ActionTypes.USER_LOGOUT, loadingInterceptor, performLogout),
  ]);
}
