import { createSelector } from "reselect";

const selectorState = (state) => state.get("user");

export const isUserAuthenticated = () =>
  createSelector(selectorState, (bstate) => bstate.isUserAuthenticated);

export const isLoading = () =>
  createSelector(selectorState, (bstate) => {
    return bstate.fetchInitiated === true && bstate.fetchCompleted === false;
  });

export const getException = () =>
  createSelector(selectorState, (bstate) => bstate.businessException);

export const getProfileStatus = () =>
  createSelector(selectorState, (bstate) => bstate.profileStatus);

export const getInsurerTypeList = () =>
  createSelector(selectorState, (bstate) => bstate.insurerTypeList);
