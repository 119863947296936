import { ActionTypes } from "../constant/constant";

export function getFetchRequestedAction() {
  return {
    type: ActionTypes.FETCH_INITIATED,
    payload: {},
  };
}

export function getFetchCompletedAction() {
  return {
    type: ActionTypes.FETCH_COMPLETED,
  };
}

export function getUnauthenticatedRequestAction() {
  return {
    type: ActionTypes.UNAUTHENTICATED_REQUEST,
  };
}
