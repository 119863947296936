import React, { Component } from "react";
import "./styles.scss";

class index extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div id="root">
        <div className="loader-container">
          <img
            className="loader-image"
            src="/images/loaders/loaderRolling.gif"
            alt="loader"
          ></img>
          <div className="animated fadeIn pt-3 text-center">Loading...</div>
        </div>
      </div>
    );
  }
}

export default index;
