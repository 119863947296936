import React, { Component } from "react";
import "./styles.scss";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// import * as ErrorConstant from "../../Constant/ErrorConstants";

import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";

export default class ModalBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
  }

  componentDidMount() {}

  componentDidUpdate() {
    this.showModal(this.props);
  }

  componentWillUnmount() {
    this.dismissModal();
  }

  showModal = (props) => {
    // $('#loginPopup').modal('show');

    // if (
    //   ErrorConstant.SESSION_EXPIRE == props.content ||
    //   ErrorConstant.NO_INTERNET_TITLE == props.content ||
    //   ErrorConstant.INVALID_ANDROID_VERSION_TITLE == props.content
    // ) {
    if (props.showModal === true && this.state.show === false) {
      this.setState({
        show: true,
      });
    }
    // } else {
    // toast.configure();
    // toast.error(props.content || "Something went wrong.");
    // }
  };

  dismissModal = () => {
    console.log("dismissModal");
    this.setState({
      show: false,
    });
    // $('#loginPopup').modal('hide');
    // $(".modal-backdrop").remove();
  };

  showSuccessBtn = (props) => {
    if (!props.success) return;
    return (
      <button
        onClick={(e) => {
          this.dismissModal();
          props.onSuccessClick(e);
        }}
        className="loginPopup-Btn"
        data-dismiss="modal"
        style={{
          backgroundColor: "rgb(51, 122, 183)",
          borderColor: "rgb(46, 109, 164)",
        }}
      >
        {props.success}
      </button>
    );
  };

  showCancelBtn = (props) => {
    if (!props.cancel) return;
    return (
      <button
        className="loginPopup-closeBtn"
        onClick={(e) => {
          this.dismissModal();
          props.onCancelClick(e);
        }}
        data-dismiss="modal"
      >
        {props.cancel}
      </button>
    );
  };

  handleLogout() {
    this.setState({
      show: false,
    });
    // this.props.handleLogout();
  }

  handleRetry() {
    this.setState({
      show: false,
    });
    window.location.reload();
  }

  handleRedirect() {
    this.setState({
      show: false,
    });
    window.open(
      "https://play.google.com/store/apps/details?id=com.hris",
      "_blank"
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.state.show === true ? (
          <Modal open={this.state.show}>
            <div className="modalPaper">
              <div>
                <div className="row" style={{ paddingLeft: "15px" }}>
                  <div className="col-9">
                    <h5>{this.props.content}</h5>
                  </div>
                  {/* <div className="col-3" style={{ textAlign: "end" }}>
                    <button
                      onClick={() => this.dismissModal()}
                      style={{ background: "white", border: "none" }}
                    >
                      X
                    </button>
                  </div> */}
                </div>
                <div
                  className="container obsent-redirect-box"
                  style={{ paddingBottom: 0 }}
                >
                  <div className="" style={{ fontSize: "12px" }}>
                    {this.props.content}
                  </div>
                  <Button
                    className="custom-cancel"
                    variant="contained"
                    style={{
                      marginTop: "18px",
                      width: "50px",
                      marginRight: "auto",
                      marginLeft: "auto",
                      display: "flex",
                    }}
                    onClick={() => this.handleLogout()}
                  >
                    ok
                  </Button>

                  {/* {ErrorConstant.SESSION_EXPIRE === this.props.content && (
                    <>
                      <div className="" style={{ fontSize: "12px" }}>
                        {ErrorConstant.SESSION_EXPIRE_MESSAGE}
                      </div>
                      <Button
                        className="custom-cancel"
                        variant="contained"
                        style={{
                          marginTop: "18px",
                          width: "50px",
                          marginRight: "auto",
                          marginLeft: "auto",
                          display: "flex",
                        }}
                        onClick={() => this.handleLogout()}
                      >
                        ok
                      </Button>
                    </>
                  )} */}

                  {/* {ErrorConstant.NO_INTERNET_TITLE === this.props.content && (
                    <>
                      <div className="" style={{ fontSize: "12px" }}>
                        {ErrorConstant.NO_INTERNET_MESSAGE}
                      </div>
                      <Button
                        className="custom-cancel"
                        variant="contained"
                        style={{
                          textTransform: "none",
                          marginTop: "18px",
                          width: "100px",
                          marginRight: "auto",
                          marginLeft: "auto",
                          display: "flex",
                        }}
                        onClick={() => this.handleRetry()}
                      >
                        Try Again
                      </Button>
                    </>
                  )} */}

                  {/* {ErrorConstant.INVALID_ANDROID_VERSION_TITLE ==
                    this.props.content && (
                    <>
                      <div className="" style={{ fontSize: "12px" }}>
                        {ErrorConstant.INVALID_ANDROID_VERSION_MESSAGE}
                      </div>
                      <Button
                        className="custom-cancel"
                        variant="contained"
                        style={{
                          textTransform: "none",
                          marginTop: "18px",
                          width: "100px",
                          marginRight: "auto",
                          marginLeft: "auto",
                          display: "flex",
                        }}
                        onClick={() => this.handleRedirect()}
                      >
                        Update
                      </Button>
                    </>
                  )} */}
                </div>
              </div>
            </div>
          </Modal>
        ) : (
          console.log("no error")
        )}
        {/* <div
          className="modal vertically-modal "
          id="loginPopup"
          style={{ margin: "15px", paddingRight: "17px" }}
        >

          
          
          <div className="modal-dialog modal-md">
            <div
              className="modal-content ml-auto mr-auto"
              style={{ backgroundColor: "#fff", border: "none", width: "75%" }}
            >
              <div className=" row no-gutters justify-content-left">
                <div className="col-12 text-left">
                  <div className="loginPopup-header"> {this.props.title} </div>
                </div>
                <div className="col-12 text-left" style={{ marginTop: "15px" }}>
                  <div className="loginPopup-infoTest">
                    {" "}
                    {this.props.content}{" "}
                  </div>
                </div>
                <div
                  className="col-12 text-right"
                  style={{ marginTop: "15px" }}
                >
                  {this.showSuccessBtn(this.props)}
                  <span style={{ paddingLeft: "10px" }}>
                    {this.showCancelBtn(this.props)}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </React.Fragment>
    );
  }
}
