import "./polyfill.jsx";
import "react-app-polyfill/stable";
import "react-app-polyfill/ie9"; // For IE 9-11 support
// import 'react-app-polyfill/ie11'; // For IE 11 support

import React from "react";
import ReactDOM from "react-dom";

import App from "./App.jsx";
import "./index.scss";

import * as serviceWorker from "./serviceWorker.jsx";
import { Provider } from "react-redux";
import { store } from "./configuration/store";

export const app = {
  run() {
    this.render(App);
  },

  render(Component) {
    const root = document.getElementById("root");

    if (root) {
      ReactDOM.render(
        <Provider store={store}>
          <Component />
        </Provider>,
        root
      );
    }
  },
};

app.run();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
