import { ActionTypes } from "../constant/constant";
import { isLoggedIn } from "../../utility/session-utils";
import { removeCookie } from "../../utility/session-utils";
import { getAgentProductMappingDTOFromCookie } from "../../utility/session-utils";

const initialState = {
  fetchInitiated: false,
  fetchCompleted: true,

  isUserAuthenticated: isLoggedIn(),

  businessException: "",
  profileStatus: {},
  insurerTypeList: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.FETCH_INITIATED: {
      state.fetchInitiated = true;
      state.fetchCompleted = false;
      return { ...state };
    }
    case ActionTypes.FETCH_COMPLETED: {
      state.fetchInitiated = false;
      state.fetchCompleted = true;
      return { ...state };
    }
    case ActionTypes.UNAUTHENTICATED_REQUEST: {
      removeCookie();
      state.isUserAuthenticated = false;
      return { ...state };
    }

    case ActionTypes.UPDATE_IS_AUTHENTICATED: {
      const isUserAuthenticated = action.payload.isUserAuthenticated;
      if (isUserAuthenticated === false) {
        removeCookie();
      }
      state.isUserAuthenticated = isUserAuthenticated;
      return { ...state };
    }

    case ActionTypes.BUSINESS_EXCEPTION: {
      state.businessException = action.payload.exception;
      return { ...state };
    }

    case ActionTypes.CHECK_USER_PROFILE_COMPLETION_RESPONSE: {
      state.profileStatus = action.payload.data;
      return { ...state };
    }

    case ActionTypes.FIND_ALL_INSURER_TYPES_RESPONSE: {
      const userAlias = getAgentProductMappingDTOFromCookie();
      var insurerTypeList = action.payload.data;
      if (userAlias !== undefined && userAlias !== null) {
        insurerTypeList = insurerTypeList.filter((itl) => {
          const agentproductMapping = userAlias.find(
            (ua) => ua.insurerTypeId == itl.id
          );
          if (agentproductMapping == null) {
            return false;
          } else {
            return true;
          }
        });
      }
      state.insurerTypeList = insurerTypeList;
      return { ...state };
    }

    default:
      return { ...state };
  }
}
