import { ActionTypes } from "../constant/constant";

export function updateIsUserAuthenticated(isUserAuthenticated) {
  return {
    type: ActionTypes.UPDATE_IS_AUTHENTICATED,
    payload: {
      isUserAuthenticated,
    },
  };
}

export function logout() {
  return {
    type: ActionTypes.USER_LOGOUT,
    payload: {},
  };
}

export function getBusinessException(exception) {
  return {
    type: ActionTypes.BUSINESS_EXCEPTION,
    payload: {
      exception,
    },
  };
}

export function apiCheckUserProfileCompletion() {
  return {
    type: ActionTypes.CHECK_USER_PROFILE_COMPLETION,
    payload: {},
  };
}

export const apiFindAllInsurerTypes = () => {
  return {
    type: ActionTypes.FIND_ALL_INSURER_TYPES,
    payload: {},
  };
};

export function getAgentProductMappingData(leadId) {
  return {
    type: ActionTypes.GET_AGENT_PRODUCT_MAPPING,
    payload: { leadId },
  };
}
