const ERRORS_MESSAGES = {
  401: "Invalid UserName Password",
  400: "Invalid UserName Password",
  404: "Invalid UserName Password",
  9999: "Something went wrong.",
  10000: "Failed to fetch.",
};

export const getResponseError = (serverErrorResponse) => {
  var errorPlaceHolder;
  if (9999 === serverErrorResponse.errorCode) {
    errorPlaceHolder = ERRORS_MESSAGES[serverErrorResponse.errorCode];
  } else if (10000 === serverErrorResponse.errorCode) {
    errorPlaceHolder = ERRORS_MESSAGES[serverErrorResponse.errorCode];
  } else {
    errorPlaceHolder = ERRORS_MESSAGES[serverErrorResponse.code];
  }

  return (
    (errorPlaceHolder &&
      errorPlaceHolder.replace(
        /%\w+%/g,
        (all) => serverErrorResponse[all.substring(1, all.length - 1)] || all
      )) ||
    ""
  );
};

export const consumeException = (action) => {
  action.consumed = true;
};

export const isConsumed = (action) => action.consumed;
