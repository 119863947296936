// import fetchDefaults from 'fetch-defaults';
// import { apiFetch } from 'utils/fetch-utils';
import { setTokenInCookie } from "./session-utils";
// import "abortcontroller-polyfill/dist/polyfill-patch-fetch";
/**
 * The FetchInterceptor class
 */

// http://192.168.1.103:8881

export default class FetchInterceptor {
  /**
   * Recognize global environment and attach fetch
   */
  constructor() {
    const ENVIRONMENT_IS_REACT_NATIVE =
      typeof navigator === "object" && navigator.product === "ReactNative";
    const ENVIRONMENT_IS_NODE =
      typeof process === "object" && typeof require === "function";
    const ENVIRONMENT_IS_WEB = typeof window === "object";
    const ENVIRONMENT_IS_WORKER = typeof importScripts === "function";

    if (ENVIRONMENT_IS_NODE) {
      this.env = global;
    } else if (ENVIRONMENT_IS_REACT_NATIVE) {
      this.env = global;
    } else if (ENVIRONMENT_IS_WORKER) {
      // this.env = self;
    } else if (ENVIRONMENT_IS_WEB) {
      this.env = window;
    } else {
      throw new Error("Unsupported environment for fetch-intercept");
    }

    this.fetch = this.env.fetch;
  }

  /**
   * Whitelist hooks
   */
  static hooks = [
    "onBeforeRequest",
    "onRequestSuccess",
    "onRequestFailure",
    "isTokenExpiry",
    "refreshToken",
    "retryRequest",
    "onConnectionRefused",
  ];

  /**
   * Register intercept hooks & return an interceptor instance
   * @param {object} hooks - The intercept hooks
   * @return {FetchInterceptor} An interceptor object
   */
  static register(hooks = {}) {
    if (this._instance) {
      return this._instance;
    }
    const interceptor = new this();
    for (let i = 0; i < this.hooks.length; i++) {
      const hook = this.hooks[i];
      if (typeof hooks[hook] === "function") {
        interceptor[hook] = hooks[hook];
      }
    }
    interceptor.hijack();
    this._instance = interceptor;
    return interceptor;
  }

  /**
   * Reset fetch and unregister intercept hooks
   */
  unregister() {
    this.env.fetch = this.fetch;
    delete this.constructor._instance;
  }

  /**
   * Hijack global fetch and insert registered hooks if present
   */
  hijack() {
    const controller = new AbortController();
    const { signal } = controller;
    this.env.fetch = (...a) => {
      let request;
      if (a[0] instanceof Request) {
        const object = {};
        [
          "cache",
          "context",
          "credentials",
          "destination",
          "headers",
          "integrity",
          "method",
          "mode",
          "redirect",
          "referrer",
          "referrerPolicy",
          "url",
          "body",
          "bodyUsed",
        ].forEach((prop) => {
          if (prop in a[0]) {
            object[prop] = a[0][prop];
          }
        });
        object.signal = signal;
        const { url, ...options } = object;
        request = new Request(url, options);
      } else {
        const url = a[0];
        const options = {
          ...a[1],
          signal,
        };
        request = new Request(url, options);
      }

      if (typeof this.onBeforeRequest === "function") {
        this.onBeforeRequest(request, controller);
      }

      const promise = this.fetch.call(this.env, request);

      if (typeof this.onAfterRequest === "function") {
        this.onAfterRequest(request, controller);
      }

      promise
        .then((response) => {
          if (
            response &&
            response.headers &&
            response.headers.get("Authorization")
          ) {
            const token = response.headers.get("Authorization");
            setTokenInCookie(token);
          }

          if (response.ok) {
            if (typeof this.onRequestSuccess === "function") {
              this.onRequestSuccess(response, request, controller);
              return response;
            }
          } else if (
            typeof this.isTokenExpiry === "function" &&
            this.isTokenExpiry(response, request, controller)
          ) {
            return this.refreshToken()
              .then((refreshResponse) => {
                if (refreshResponse.ok) {
                  return refreshResponse.json();
                }
              })
              .then((tokenJson) => {
                if (tokenJson) {
                  setTokenInCookie(tokenJson.token);
                  request.headers.set(
                    "Authorization",
                    `Bearer ${tokenJson.token}`
                  );
                  return this.fetch.call(this.env, request);
                }
                return "unknown";
              });
          } else if (typeof this.onRequestFailure === "function") {
            this.onRequestFailure(response, request, controller);
            return response;
          }
        })
        .catch((error) => {
          this.onConnectionRefused(error, controller);
          return { status: 10000 };
        });

      // controller.abort();
      // window.stop();

      return promise;
    };
  }
}
